import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"

class OpenPage extends Component{

  constructor(props) {
    super(props);
    this.state = {
      metrics: {}
    }
  }

  componentDidMount() {
    fetch('https://us-central1-linksaber-97603.cloudfunctions.net/api/v1/open', {
      method: 'POST',
      body: {}
    }).then(body => body.json())
      .then(body => {
        console.log(body)
        this.setState({
          metrics: body
        })
      })
  }

  render() {
    return(
      <Layout>
        <SEO title="Linksaber | Open Metrics" />

        <Header/>

        <p className="section-title">
          Open metrics
        </p>
        <p className="section-body text-gray-400">
          Total: {this.state.metrics.totalUsers}<br/>
          Active (last 7 days): {this.state.metrics.activeUsers7}<br/>
          Active (last 14 days): {this.state.metrics.activeUsers14}<br/>
          Unsubscribed: {this.state.metrics.unsubscribedUsers}<br/>
          Sent emails: {this.state.metrics.sentEmails}<br/>
          Links added: {this.state.metrics.addedLinks}<br/>
        </p>
      </Layout>
    )
  }
}
export default OpenPage